import React, { useState } from "react";
import * as js from "js-beautify";

const JavascriptBeautifier = () => {
  const [input, setInput] = useState("");

  const handleOnChange = (e) => {
    setInput(e.target.value);
  };

  const handleBeautify = () => {
    if (input) {
      const data = js.js_beautify(input);
      setInput(data);
    }
  };

  return (
    <div
      className="mx-8 lg:mx-0"
      style={{ maxWidth: "1000px", margin: "32px auto" }}
    >
      <h1 className="text-center mb-10 text-5xl font-semibold">
        Javascript Beautifier
      </h1>
      <div className="max-w-3xl	m-auto">
        <p className="text-gray-700 text-center mb-10">
          Elevate Your Code: Introducing JavaScript Beautifier
        </p>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "300px 1fr",
          gridAutoRows: "auto",
          minHeight: "700px",
        }}
        className="border-2"
      >
        <div className="border-r-2">
          <div className="p-4">
            <button
              type="button"
              onClick={handleBeautify}
              className="focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 w-full"
            >
              Beautify
            </button>
          </div>
        </div>
        <div className="p-3">
          <textarea
            rows="33"
            value={input}
            onChange={handleOnChange}
            className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Paste Javascript code here"
          />
        </div>
      </div>
      <div className="mt-10">
        <h2 className="text-2xl font-bold">How It Works:</h2>
        <div className="mt-4">
          <p>Paste Javascript code and then click on Beautify.</p>
        </div>
      </div>
    </div>
  );
};

export default JavascriptBeautifier;
