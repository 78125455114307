import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const BasicLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div style={{ minHeight: "500px" }}>{children}</div>
      <Footer />
    </div>
  );
};

export default BasicLayout;
