import React from "react";

const Footer = () => {
  return (
    <div className="border-t border-gray-100 mt-20 mx-8 lg:mx-0">
      <footer className="pt-10 m-auto" style={{ maxWidth: "1000px" }}>
        <div className="wrapper">
          <div className="lg:container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div>
              <p className="capitalize text-xl mb-4">Category</p>
              <ul>
                <li>
                  <a href="/" className="m-2 mb-3 flex">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://frontendroom.com/shorts/"
                    className="m-2 mb-3 flex"
                  >
                    Shorts
                  </a>
                </li>
                <li>
                  <a
                    href="https://frontendroom.com/interviews/"
                    className="m-2 mb-3 flex"
                  >
                    Interviews
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className="capitalize text-xl mb-4">Website</p>
              <ul>
                <li>
                  <a
                    href="https://frontendroom.com/about-us/"
                    className="m-2 mb-3 flex"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="https://frontendroom.com/privacy-policy/"
                    className="m-2 mb-3 flex"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className="capitalize text-xl mb-4">Social</p>
              <ul>
                <li>
                  <a
                    href="https://github.com/isamrish"
                    target="_blank"
                    rel="noreferrer"
                    className="mb-3 flex"
                  >
                    GitHub
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/isamrishk"
                    target="_blank"
                    rel="noreferrer"
                    className="mb-3 flex"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <img
                src="/frontendroom.png"
                alt="Frontendroom"
                style={{ height: "48px" }}
              />
            </div>
          </div>
          <div className="my-8 text-center">
            <span>
              &copy; {new Date().getFullYear()} Frontendroom. All rights
              reserved.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
