import React from "react";
import ToolsCard from "./components/ToolsCard";
import { tools } from "./data/tools";

const Home = () => {
  return (
    <div
      className="max-w-5xl mx-8 lg:mx-0"
      style={{ maxWidth: "1000px", margin: "32px auto" }}
    >
      <h2 className="text-center text-4xl lg:text-5xl max-w-2xl leading-tight m-auto">
        Software Development, Simplified with Tools.
      </h2>
      <div className="mt-16 grid grid-cols-1 sm:grid-cols-3 grid-cols-3 gap-6">
        {tools?.map((res) => {
          return (
            <ToolsCard
              title={res.title}
              description={res.description}
              href={res.href}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
