import React from "react";

const Header = () => {
  return (
    <header className="bg-blue-700 text-white">
      <nav
        style={{ maxWidth: "1000px" }}
        className="flex items-center mx-10 h-16"
      >
        <a href="/" className="flex justify-center items-end text-white">
          <h2 className="text-2xl font-black">Tools</h2>
          <p>(by Frontendroom)</p>
        </a>
      </nav>
    </header>
  );
};

export default Header;
