import React, { useState } from "react";

export async function getSVGRCode(code, options = {}) {
  const res = await fetch("https://api.react-svgr.com/api/svgr", {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: JSON.stringify({ code, options }),
  });
  const json = await res.json();
  if (json.error) throw new Error(json.error);
  return json.output;
}

const SVGtoReactConverter = () => {
  const [converted, setConverted] = useState("");
  const [isConverting, setIsConverting] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isInputed, setIsInputed] = useState(false);
  const [input, setInput] = useState("");
  const [uploadInput, setUploadInput] = useState("");

  const handleInput = (e) => {
    setInput(e.target.value);
    setIsUploaded(true);
    setIsInputed(false);
  };

  const convertToReact = async (svgCode) => {
    setIsConverting(true);
    try {
      const result = await getSVGRCode(svgCode);
      setConverted(result);
      setIsConverting(false);
    } catch (err) {
      console.error(err);
      setIsConverting(false);
    }
  };

  const handleConvert = () => {
    if (isInputed) {
      convertToReact(input);
    }
    if (isUploaded) {
      convertToReact(uploadInput);
    }
  };

  const onUpload = () => {
    const upload_svg = document.getElementById("upload_svg").files[0];
    if (upload_svg) {
      var reader = new FileReader();
      reader.readAsText(upload_svg, "UTF-8");
      reader.onload = function (evt) {
        const result = evt.target.result;
        setUploadInput(result);
        setIsInputed(false);
        setIsUploaded(true);
      };
      reader.onerror = function (evt) {
        console.log("error reading file");
      };
    }
  };

  return (
    <div
      className="mx-8 lg:mx-0"
      style={{ maxWidth: "1000px", margin: "32px auto" }}
    >
      <h1 className="text-center mb-10 text-5xl font-semibold">
        SVG to React Converter
      </h1>
      <h4 className="text-center mb-4 text-xl">
        With this simple tool, convert SVG to React
      </h4>
      <div className="max-w-3xl	m-auto">
        <p className="text-gray-700 text-center mb-10">
          Effortlessly convert SVG files to React components with our
          user-friendly SVG to React Converter.
        </p>
      </div>
      <hr />
      <div class="flex mt-10">
        <div className="pr-8 w-full">
          <div className="pb-4">
            <label
              class="block mb-2 text-md font-medium text-gray-900"
              for="upload_svg"
            >
              Upload SVG
            </label>
            <input
              class="block w-full mb-3 text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
              id="upload_svg"
              accept=".svg"
              type="file"
              onChange={onUpload}
              disabled={isConverting}
            />
            <p class="text-sm text-gray-500" id="file_input_help">
              SVG file only
            </p>
          </div>
          <div className="py-6">
            <p className="font-bold">OR</p>
          </div>
          <div className="pb-4">
            <label
              for="svg_code"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Paste SVG Code
            </label>
            <textarea
              id="svg_code"
              rows="11"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
              value={input}
              onChange={handleInput}
              disabled={isConverting}
            ></textarea>
          </div>
        </div>
        <div className="pl-8 flex justify-center items-center	 h-100">
          <button
            type="button"
            onClick={handleConvert}
            class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
          >
            Convert
          </button>
        </div>
        <div className="pl-8 w-full">
          <label
            for="react_component"
            class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
          >
            React Component
          </label>
          <textarea
            id="react_component"
            rows="20"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            disabled
            value={converted}
          ></textarea>
        </div>
      </div>
      <div className="mt-10">
        <h2 className="text-2xl font-bold">How It Works:</h2>
        <div className="mt-4">
          <p>
            Either upload or paste svg code in input box and then click on
            Convert button.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SVGtoReactConverter;
