import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import BasicLayout from "./layout/BasicLayout";
import Home from "./Home";
import JWTParser from "./tools/JWTparser";
import LoremIpsum from "./tools/LoremIpsum";
import SVGtoReactConverter from "./tools/SVGtoReactConverter";
import JavascriptBeautifier from "./tools/JavascriptBeautifier";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "jwt-debugger",
    element: <JWTParser />,
  },
  {
    path: "lorem-ipsum",
    element: <LoremIpsum />,
  },
  {
    path: "svg-to-react-converter",
    element: <SVGtoReactConverter />,
  },
  {
    path: "javascript-beautifier",
    element: <JavascriptBeautifier />,
  },
]);

root.render(
  <React.StrictMode>
    <BasicLayout>
      <RouterProvider router={router} />
    </BasicLayout>
  </React.StrictMode>
);

reportWebVitals();
