export const tools = [
  {
    title: "JWT Debugger",
    description:
      "Unraveling Security, Empowering Insight, and Decoding Trust with Precision and Simplicity.",
    href: "/jwt-debugger",
  },
  {
    title: "Lorem Ipsum Generator",
    description: "Creates placeholder Latin-like text for design mockups.",
    href: "/lorem-ipsum",
  },
  {
    title: "SVG to React Converter",
    description:
      "Effortlessly convert SVG files to React components with our user-friendly SVG to React Converter.",
    href: "/svg-to-react-converter",
  },
  {
    title: "Javascript Beautifier",
    description:
      "Effortlessly enhance the readability and structure of your JavaScript code without altering its functionality using Javascript Beautifier.",
    href: "/javascript-beautifier",
  },
];
