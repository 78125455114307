import React, { useState } from "react";
import { loremIpsum } from "lorem-ipsum";

const LoremIpsum = () => {
  const [selectedOption, setSelectedOption] = useState("paragraphs");
  const [value, setValue] = useState(10);
  const [generated, setGenerated] = useState("");

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleGenerate = (e) => {
    const result = loremIpsum({
      count: +value,
      format: "plain",
      units: selectedOption,
    });
    setGenerated(result);
  };

  return (
    <div
      className="mx-8 lg:mx-0"
      style={{ maxWidth: "1000px", margin: "32px auto" }}
    >
      <h1 className="text-center mb-10 text-5xl font-semibold">
        Lorem Ipsum Generator
      </h1>
      <h4 className="text-center mb-4 text-xl">
        Generate Placeholder Text with Lorem Ipsum
      </h4>
      <div className="max-w-3xl	m-auto">
        <p className="text-gray-700 text-center mb-10">
          Welcome to our Lorem Ipsum Generator - the perfect tool for designers,
          developers, and content creators. Whether you're working on a website,
          graphic design, or any creative project, our Lorem Ipsum generator
          simplifies the process of filling your layout with realistic-looking
          text.
        </p>
      </div>
      <hr />
      <div className="grid grid-cols-2 gap-4">
        <div className="text-left mt-4">
          <p className="text-2xl mb-3 font-semibold">Generate</p>
          <div className="flex mb-5">
            <div className="flex items-center me-4">
              <input
                id="inline-radio"
                type="radio"
                value="words"
                checked={selectedOption === "words"}
                onChange={handleSelectionChange}
                name="inline-radio-group"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label
                htmlFor="inline-radio"
                className="ms-2 text-lg font-medium text-gray-900"
              >
                Words
              </label>
            </div>
            <div className="flex items-center me-4">
              <input
                id="inline-2-radio"
                type="radio"
                value="sentences"
                checked={selectedOption === "sentences"}
                onChange={handleSelectionChange}
                name="inline-radio-group"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label
                htmlFor="inline-2-radio"
                className="ms-2 text-lg font-medium text-gray-900"
              >
                Sentences
              </label>
            </div>
            <div className="flex items-center me-4">
              <input
                id="inline-checked-radio"
                type="radio"
                checked={selectedOption === "paragraphs"}
                onChange={handleSelectionChange}
                value="paragraphs"
                name="inline-radio-group"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label
                htmlFor="inline-checked-radio"
                className="ms-2 text-lg font-medium text-gray-900"
              >
                Paragraphs
              </label>
            </div>
          </div>
          <p className="text-xl mb-4 font-semibold">With The Amount Of</p>
          <div className="mb-6 flex">
            <input
              type="number"
              id="default-input"
              placeholder=""
              value={value}
              onChange={handleOnChange}
              className="bg-gray-50 px-4 border border-gray-300 text-gray-900 text-sm rounded h-8 mr-4"
            />
            <p className="text-lg" s>
              {selectedOption}
            </p>
          </div>
        </div>
        <div className="mt-4 flex justify-end items-end">
          <button
            type="button"
            onClick={handleGenerate}
            class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-6 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
          >
            Generate
          </button>
        </div>
      </div>
      <div className="mt-12">
        <label
          htmlFor="lorem-ipsum-generated-text"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Generated Lorem Ipsum Text
        </label>
        <textarea
          id="lorem-ipsum-generated-text"
          rows="20"
          value={generated}
          className="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
        ></textarea>
      </div>
    </div>
  );
};

export default LoremIpsum;
