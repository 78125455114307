import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";

const JWTParser = () => {
  const [token, setToken] = useState("");
  const [decoded, setDecoded] = useState("");
  const [decodedHeader, setDecodedHeader] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (e) => {
    handleThings(e.target.value);
    setToken(e.target.value);
    if (e.target.value === "") {
      setDecoded("");
      setDecodedHeader("");
    }
  };

  const handleThings = (text) => {
    try {
      const decoded = jwtDecode(text);
      setDecoded(decoded);
      const decodedHeader = jwtDecode(text, { header: true });
      setDecodedHeader(decodedHeader);
      setIsInvalid(false);
    } catch (err) {
      setDecoded("");
      setDecodedHeader("");
      setIsInvalid(true);
    }
  };

  return (
    <div
      className="mx-8 lg:mx-0"
      style={{ maxWidth: "1000px", margin: "32px auto" }}
    >
      <h1 className="text-center mb-10 text-5xl font-semibold">JWT Debugger</h1>
      <div className="max-w-3xl	m-auto">
        <p className="text-gray-700 text-center mb-10">
          Are you grappling with decoding cryptic JWTs? Struggling to understand
          the intricacies of the data they carry? Look no further! Introducing
          our state-of-the-art JWT Debugger - the ultimate tool to demystify and
          streamline your JSON Web Tokens effortlessly.
        </p>
      </div>
      <div className="bg-yellow-200	p-4 mb-6 rounded">
        <p>
          Note: JWTs are sensitive credentials. Use with care, as we don't store
          tokens, and all validation occurs client-side.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 border border-slate-500 p-10 rounded">
        <div>
          <label
            htmlFor="message"
            className="block mb-9 text-xl font-medium text-gray-900"
          >
            JWT Token (Encoded Text)
          </label>
          <textarea
            id="message"
            rows="12"
            className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-base"
            placeholder="Place JWT token here!"
            onChange={handleChange}
            value={token}
          />
          {token?.length ? (
            <div className="h-24 pt-6">
              <div>
                <h3
                  className={`${
                    isInvalid ? "text-red-400" : "text-green-400"
                  } text-3xl`}
                >
                  {isInvalid ? "Token is invalid" : "Token verified!!!"}
                </h3>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor="message"
            className="block mb-2 text-xl font-medium text-gray-900"
          >
            Decoded
          </label>
          <label
            for="message"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Header
          </label>
          <textarea
            id="message"
            rows="6"
            className={`block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 ${
              decoded ? "bg-slate-200" : "bg-gray-50"
            }`}
            placeholder=""
            value={decoded ? JSON.stringify(decoded, null, 2) : ""}
            disabled
          />
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Payload
          </label>
          <textarea
            id="message"
            rows="6"
            className={`block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 ${
              decodedHeader ? "bg-slate-200" : "bg-gray-50"
            }`}
            placeholder=""
            value={decodedHeader ? JSON.stringify(decodedHeader, null, 2) : ""}
            disabled
          />
        </div>
      </div>
      <div className="mt-10">
        <h2 className="text-2xl font-bold">How It Works:</h2>
        <div className="mt-4">
          <p className="font-bold">Just Paste Your JWT:</p>
          <p>
            Simply paste your JWT into the input field. It will decode the JWT
            Token.
          </p>
        </div>
      </div>
    </div>
  );
};

export default JWTParser;
